/**
 * Sort fragments for consolidation
 * @param {Object} context
 * @param {Int32Array} context.fragIds - Fragment Identifier
 * @param {Uint8Array|Uint16Array} context.geomIds - Geometry Identifier
 * @param {Uint32Array} context.memCosts - Memory cost of each fragment
 * @param {Uint8Array|Uint16Array} context.materialIds - Material Identifier
 * @param {Uint32Array} [context.fragIdToNodeIdx] - optional mapping from fragment id to bvh node index
 */
export function sortFragments(fragIds, geomIds, memCosts, materialIds, fragIdToNodeIdx) {
    // define sort predicate
    function fragCompare(fragId1, fragId2) {

        // compute consolidation costs of both fragments
        var geomId1 = geomIds[fragId1];
        var geomId2 = geomIds[fragId2];
        var memCost1 = memCosts[fragId1];
        var memCost2 = memCosts[fragId2];

        // 1. memCost
        if (memCost1 != memCost2) {
            return memCost1 - memCost2;
        }

        // 2. geom id
        if (geomId1 != geomId2) {
            return geomId1 - geomId2;
        }

        // 3. material id
        var mat1 = materialIds[fragId1];
        var mat2 = materialIds[fragId2];

        if (mat1 != mat2) {
            return mat1 - mat2;
        }
        
        // 4. bvh node id
        return fragIdToNodeIdx ? fragIdToNodeIdx[fragId1] - fragIdToNodeIdx[fragId2] : 0;
    }

    // sort by costs
    fragIds.sort(fragCompare);
    return fragIds;
}
